<script setup>
import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

const props = defineProps({
    icon: String,
    iconDutch: String,
});

const locale = computed(() => usePage().props.locale);

const localeIcon = computed(() => (props.iconDutch != null && locale.value == 'nl' ? props.iconDutch : props.icon));
const isRaw = computed(() => localeIcon.value.includes('<svg'));
</script>
<template>
    <span
        v-if="isRaw"
        v-html="localeIcon"
    />
    <img
        v-else
        :src="localeIcon"
    >
</template>
